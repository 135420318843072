import { useEffect, useState } from 'react';

export function useResource<T>(
  loader: () => Promise<T>,
  initialValue: T = null,
  deps: UnsafeAny[] = [],
): [boolean, T, (value: T) => void] {
  const [state, setState] = useState({
    loading: true,
    value: initialValue ?? null,
  });

  useEffect(() => {
    async function load() {
      const value = await loader();
      setState({ loading: false, value });
    }
    load();
  }, deps);

  return [
    state.loading,
    state.value,
    nextValue => {
      setState(prev => ({ ...prev, value: nextValue }));
    },
  ];
}
