import React, { useState } from 'react';
import cn from 'classnames';

import Image, { type ImageProps } from './Image';

import styles from './imagePreview.module.scss';

export default function ImagePreview(props: ImageProps) {
  const [opened, setOpened] = useState(false);

  return (
    <div className={cn(styles.imagePreview, { opened })}>
      <Image {...props} onClick={() => setOpened(!opened)} />
      <div className={styles.backdrop} onClick={() => setOpened(false)} />
    </div>
  );
}
