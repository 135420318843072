import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatNumber } from '../../helpers';
import { useResource } from '../../hooks/useResource';
import { fetchHandoverStatus } from '../../model/TripHandover';
import type { ReduxState } from '../../store';
import { flashMessageError } from '../../store/session';
import ImagePreview from '../ImagePreview';
import Loader from '../Loader';
import T, { TContext } from '../Translate';

interface Props {
  hash: string;
  part: 'handin' | 'handout';
}

export default function TripDetailHandover(props: Props) {
  const dispatch = useDispatch();
  const { language: lang } = useSelector((store: ReduxState) => store.app);
  const [loading, handover] = useResource(
    async () => {
      try {
        const handoverStatus = await fetchHandoverStatus(props.hash);
        return handoverStatus[props.part === 'handin' ? 'handover_in' : 'handover_out'];
      } catch (error) {
        dispatch(flashMessageError(error.message));
      }
    },
    null,
    [props.hash],
  );

  if (loading) {
    return (
      <main className="main-container container container--top container--bottom">
        <Loader />
      </main>
    );
  }

  return (
    <TContext name={props.part}>
      <main className="main-container container container--sm container--top container--bottom">
        <T as="h1" id="tripDetail.handover.title" />
        <T as="p" id="tripDetail.handover.description" />

        <ul className="list">
          <li className="list__item">
            <div className="list__item--name">
              <T id="tripDetail.handover.fuelLevel" />
            </div>
            <div className="list__item--info">{handover.fuel_level} %</div>
          </li>
          <li className="list__item">
            <div className="list__item--name">
              <T id="tripDetail.handover.mileage" />
            </div>
            <div className="list__item--info">
              <T data={{ mileage: formatNumber(handover.mileage, lang, 0) }} id="tripDetail.handover.mileageKm" />
            </div>
          </li>
          {props.part === 'handin' && handover.note ? (
            <li className="list__item">
              <div className="list__item--name">
                <T id="tripDetail.handover.note" />
              </div>
              <div className="list__item--info">{handover.note}</div>
            </li>
          ) : null}
        </ul>

        <div className="row">
          {handover.documents.map(image => (
            <div className="col-12 col-lg-6 mb-3" key={image.hash}>
              <ImagePreview alt="handover photo" className="rounded-lg" src={image} />
            </div>
          ))}
        </div>
      </main>
    </TContext>
  );
}
