import { PrivateApiV3 } from '../services/Api';

import type { HandoverStatus } from './Trip';

export interface TripHandover {
  handover_in: HandoverStatus;
  handover_out: HandoverStatus;
}

export async function fetchHandoverStatus(tripHash: string) {
  const response = await PrivateApiV3.get<TripHandover>(`/handovers/${tripHash}/status`);
  return response.data;
}
