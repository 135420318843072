import React from 'react';
import type { PageProps } from 'gatsby';

import PageLayout from '../../../views/Layout/Page';
import TripDetailHandover from '../../../views/Trip/TripDetailHandover';

export default function TripDetailHandOutPage({ params }: PageProps) {
  return (
    <PageLayout pageview="trip_detail_handout" private>
      <TripDetailHandover hash={params.hash} part="handout" />
    </PageLayout>
  );
}
